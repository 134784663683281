<template>
  <div>
    <h1
      v-if="pageHeading !== ''"
      class="mt-2 mb-2"
    >
      {{ pageHeading }}
    </h1>
    <b-card>
      <paymentsTotals />
    </b-card>
    <b-card>
      <paymentsTable :batch-id="this.$route.query.batchid" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import paymentsTotals from '@/components/payments/PaymentsTotals.vue'
import paymentsTable from '@/components/payments/PaymentsTable.vue'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    paymentsTotals,
    paymentsTable,
  },
  computed: {
    ...mapGetters({ paymentInfo: 'app/getPaymentsForCurrentClient' }),
    pageHeading() {
      if (this.paymentInfo === undefined || this.paymentInfo.client === undefined) return ''
      if (this.paymentInfo.client.length === 0) return ''

      return `${this.paymentInfo.client[0].name} - week ${this.paymentInfo.weekno}`
    },
  },
}
</script>

<style>

</style>
