<template>
  <div>
    <b-row
      :key="rowData.id"
      variant="primary"
      class="w-100 mb-0 ml-auto mr-auto"
    >
      <div
        cols="5"
        class="ml-1 mr-3"
        v-html="getLabourAndExtrasTables(rowData)"
      />
      <div
        cols="5"
        class="ml-1"
        v-html="getChargesAndFeesTables(rowData)"
      />
    </b-row>
  </div>
</template>

<script>
import { BRow } from 'bootstrap-vue'

export default {
  name: 'PaymentsSubDetails',
  components: {
    BRow,
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    getChargesFeesTableHeader(rowData) {
      /* eslint-disable */
      if (rowData.chargeelements.length === 0 && (rowData.feeelements['Invoice'] === undefined || rowData.feeelements['Invoice'] === 0) && (rowData.feeelements['Tax Service'] === undefined || rowData.feeelements['Tax Service'] === 0)) {
      /* eslint-enable */
        return ''
      }

      return '<table><thead><th>ITEM</th><th class="text-right">QTY</th><th class="text-right">PRICE</th><th class="text-right">AMOUNT</th></thead>'
    },
    getChargesRows(rowData) {
      let output = ''
      let bgColor = '#FAFAFC'

      rowData.chargeelements.forEach(element => {
        output += `<tr style="background-color:${bgColor};"><td>${element.desc}</td><td class="text-right">${element.qty}</td><td class="text-right">${this.formatCurrency(element.unitprice)}</td><td class="text-right">${this.formatCurrency(element.subtotal)}</td></tr>`

        if (bgColor === '#FAFAFC') {
          bgColor = '#FFFFFF'
        } else {
          bgColor = '#FAFAFC'
        }
      })

      if (output.length > 0) {
        output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">CHARGES</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(rowData.charges)}</td></tr>`
      }

      return output
    },
    getFeesRows(rowData) {
      let output = ''
      /* eslint-disable */
      if (rowData.feeelements['Tax Service'] !== undefined && rowData.feeelements['Tax Service'] !== 0) {
        output += `<tr style="background-color:#FAFAFC;"><td>Tax service</td><td class="text-right">-</td><td class="text-right">${this.formatCurrency(rowData.feeelements['Tax Service'])}</td><td class="text-right">${this.formatCurrency(rowData.feeelements['Tax Service'])}</td></tr>`
      }
      if (rowData.feeelements['Invoice'] !== undefined && rowData.feeelements['Invoice'] !== 0) {
        let bgColor = '#FAFAFC'
        if (output.length > 0) {
          bgColor = '#FFFFFF'
        }
        output += `<tr style="background-color:${bgColor};"><td>Invoice</td><td class="text-right">${rowData.feeelements['Invoice']/2}</td><td class="text-right">${this.formatCurrency(2)}</td><td class="text-right">${this.formatCurrency(rowData.feeelements['Invoice'])}</td></tr>`
      }

      if (output.length > 0) {
        output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">SCL FEES</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(rowData.fees)}</td></tr>`
      }

      /* eslint-enable */
      return output
    },
    getDeductionsRow(rowData) {
      const output = `<tr style="opacity: 0;"><td></td><td></td><td></td><td></td></tr><tr style="background-color:#FAFAFC !important;"><td class="font-weight-bold">TOTAL DEDUCTIONS (INC. VAT)</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(parseFloat(rowData.charges) + parseFloat(rowData.fees))}</td></tr>`
      return output
    },
    getCISTaxRow(rowData) {
      const output = `<tr style="opacity: 0;"><td></td><td></td><td></td><td></td></tr><tr style="background-color:#F3F2F7 !important;"><td class="font-weight-bold">CIS TAX</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(parseFloat(rowData.tax))}</td></tr>`
      return output
    },
    getPaymentRow(rowData) {
      const output = `<tr style="opacity: 0;"><td></td><td></td><td></td><td></td></tr><tr style="background:#3cb3a21f !important;"><td class="font-weight-bold">PAYMENT</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(parseFloat(rowData.pay))}</td></tr>`
      return output
    },
    getChargesAndFeesTables(rowData) {
      let output = this.getChargesFeesTableHeader(rowData)
      if (output.length === 0) return ''

      output += this.getChargesRows(rowData)
      output += this.getFeesRows(rowData)
      output += this.getDeductionsRow(rowData)
      output += this.getCISTaxRow(rowData)
      output += this.getPaymentRow(rowData)
      output += '</table>'
      return output
    },
    getLabourExtrasTableHeader(rowData) {
      if (rowData.paylabourelements.length === 0 && rowData.paybonuselements.length === 0 && rowData.paymaterialselements.length === 0) {
        return ''
      }

      return '<table><thead><th>TYPE</th><th class="text-right">DAYS</th><th class="text-right">RATE</th><th class="text-right">AMOUNT</th></thead>'
    },
    getLabourRows(rowData) {
      if (rowData.paylabourelements.length === 0) {
        return ''
      }

      let output = ''
      let bgColor = '#FAFAFC'
      let totalCharges = 0

      rowData.paylabourelements.forEach(element => {
        output += `<tr style="background-color:${bgColor};"><td>${element.paytype}</td><td class="text-right">${this.formatDecimalPlaces(element.days)} (${element.hours})</td><td class="text-right">${this.formatCurrency(element.dayrate)}</td><td class="text-right">${this.formatCurrency(element.charge)}</td></tr>`
        totalCharges += parseFloat(element.charge)
        if (bgColor === '#FAFAFC') {
          bgColor = '#FFFFFF'
        } else {
          bgColor = '#FAFAFC'
        }
      })

      output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">LABOUR</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(totalCharges)}</td></tr>`
      return output
    },
    getBonusRows(rowData) {
      if (rowData.paybonuselements.length === 0) {
        return ''
      }

      let output = ''
      let bgColor = '#FAFAFC'
      let totalBonuses = 0

      rowData.paybonuselements.forEach(element => {
        output += `<tr style="background-color:${bgColor};"><td>${element.paytype}</td><td class="text-center">-</td><td class="text-right">${this.formatCurrency(element.dayrate)}</td><td class="text-right">${this.formatCurrency(element.charge)}</td></tr>`
        totalBonuses += parseFloat(element.charge)
        if (bgColor === '#FAFAFC') {
          bgColor = '#FFFFFF'
        } else {
          bgColor = '#FAFAFC'
        }
      })

      output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">EXTRAS</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(totalBonuses)}</td></tr>`
      return output
    },
    getMaterialsRows(rowData) {
      if (rowData.paymaterialselements.length === 0) {
        return ''
      }

      let output = ''
      let bgColor = '#FAFAFC'
      let totalMaterials = 0

      rowData.paymaterialselements.forEach(element => {
        output += `<tr style="background-color:${bgColor};"><td>${element.paytype}</td><td class="text-center">-</td><td class="text-right">${this.formatCurrency(element.dayrate)}</td><td class="text-right">${this.formatCurrency(element.charge)}</td></tr>`
        totalMaterials += parseFloat(element.charge)
        if (bgColor === '#FAFAFC') {
          bgColor = '#FFFFFF'
        } else {
          bgColor = '#FAFAFC'
        }
      })

      output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">MATERIALS</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(totalMaterials)}</td></tr>`
      return output
    },
    getTotalsRows(rowData) {
      let output = '<tr style="opacity: 0;"><td></td><td></td><td></td><td></td></tr>'
      if (rowData.operative[0].operativedetails[0].vatreg === true) {
        output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">SUB TOTAL</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(parseFloat(rowData.invsubtotal))}</td></tr>`
        output += `<tr><td class="font-weight-bold">VAT</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(parseFloat(rowData.invvat))}</td></tr>`
      }
      output += `<tr style="background-color:#F3F2F7;"><td class="font-weight-bold">INVOICE TOTAL</td><td></td><td></td><td class="font-weight-bold text-right">${this.formatCurrency(parseFloat(rowData.invtotal))}</td></tr>`
      return output
    },
    getLabourAndExtrasTables(rowData) {
      let output = this.getLabourExtrasTableHeader(rowData)
      if (output.length === 0) return ''

      output += this.getLabourRows(rowData)
      output += this.getBonusRows(rowData)
      output += this.getMaterialsRows(rowData)
      output += this.getTotalsRows(rowData)
      output += '</table>'
      return output
    },
    formatDecimalPlaces(value) {
      if (typeof value === 'string') return Number(value).toFixed(2)

      return value.toFixed(2)
    },
    formatCurrency(value) {
      return `£${this.formatDecimalPlaces(value)}`
    },
  },
}
</script>

<style scoped>

</style>
