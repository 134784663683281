<template>
  <div>
    <b-table-simple
      :borderless="true"
      :small="true"
    >
      <b-tbody>
        <b-tr>
          <b-td class="scl-total-heading text-center">
            Operatives
          </b-td>
          <b-td class="scl-total-heading text-center">
            Net from client
          </b-td>
          <b-td class="scl-total-heading text-center">
            Client fee
          </b-td>
          <b-td class="scl-total-heading text-center">
            Invoice Total Ex VAT
          </b-td>
          <b-td class="scl-total-heading text-center">
            Operative Fees
          </b-td>
          <b-td class="scl-total-heading text-center">
            Operative Charges
          </b-td>
          <b-td class="scl-total-heading text-center">
            CIS Tax
          </b-td>
          <b-td class="scl-total-heading text-center">
            Operative VAT
          </b-td>
          <b-td class="scl-total-heading text-center">
            BACS payment
          </b-td>
          <b-td
            rowspan="2"
            class="scl-total-heading text-center"
          >
            <b-button
              v-b-modal.modal-xs
              type="submit"
              variant="primary"
              block
              :disabled="isInvalid"
              class="scl-button-size-settings float-right"
            >
              Generate BACS
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="scl-total-data text-center">
            {{ paymentInfo.itemcount }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.totalprefee }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.clientfee }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.clientpayment }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.opfees }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.opcharges }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.totaltax }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.opvat }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.totalpay }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal
      id="modal-xs"
      cancel-variant="outline-secondary"
      cancel-title="Cancel"
      ok-title="Generate file"
      centered
      no-close-on-backdrop
      hide-header-close
      size="xs"
      title="Generate BACS file"
      @ok="generateBACScheck"
    >
      <b-card-text>
        <p>
          Before generating a BACS file it's recommended that you get a payment from the client.
        </p>
      </b-card-text>
      <b-card-text>
        <b-badge
          class="profile-badge mb-1"
          variant="light-primary"
        >
          {{ clientName }}
        </b-badge>
      </b-card-text>
      <b-card-text>
        <p>
          Week: {{ paymentInfo.weekno }}
        </p>
      </b-card-text>
      <b-card-text>
        <p>
          Invoice Total Ex VAT: £{{ paymentInfo.clientpayment }}
        </p>
      </b-card-text>
      <!-- <b-card-text>
        <p>
          Service fee: £{{ paymentInfo.fees }}
        </p>
      </b-card-text> -->
      <b-card-text>
        <p>
          BACS Payment: £{{ paymentInfo.totalpay }}
        </p>
      </b-card-text>
    </b-modal>

    <b-modal
      id="error-modal"
      ref="error-modal"
      title="Error"
      ok-only
      ok-title="Close"
      ok-variant="danger"
      centered
      hide-header-close
      modal-class="modal-danger"
    >
      <b-card-text>
        There are Operatives with errors, a BACS file cannot be generated.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTableSimple, BTr, BTd, BTbody, BButton, VBModal, BModal, BBadge, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentsTotals',
  components: {
    BTableSimple,
    BTr,
    BTd,
    BTbody,
    BButton,
    BModal,
    BBadge,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isInvalid: false,
    }
  },
  computed: {
    ...mapGetters({ paymentInfo: 'app/getPaymentsForCurrentClient' }),
    dataIsValid() {
      if (this.paymentInfo === undefined || this.paymentInfo.client === undefined) return false
      if (this.paymentInfo.client.length === 0) return false

      return true
    },
    clientName() {
      if (this.dataIsValid === false) return ''

      return this.paymentInfo.client[0].name
    },
  },
  methods: {
    checkForErrorsOrWarnings() {
      let result = 0
      for (let iPayment = 0; iPayment < this.paymentInfo.payments.length; iPayment += 1) {
        // Skip ones on Hold or with a greater status
        /* eslint-disable */
        if (this.paymentInfo.payments[iPayment].status >= '3') {
          continue
        }
        /* eslint-enable */
        if (this.paymentInfo.payments[iPayment].operative[0].operativeproblems.warningcount > 0) {
          result = 1 // Found a warning
        }

        if (this.paymentInfo.payments[iPayment].operative[0].operativeproblems.errorcount > 0) {
          result = -1 // Found an error, so break out
          break
        }
      }

      return result
    },
    displayErrorMessage() {
      this.$refs['error-modal'].show()
    },
    displayWarningMessage() {
      /* eslint-disable */
      this.$bvModal
        .msgBoxConfirm('There are Operatives with warnings. Do you still wish to generate the BACS file?', {
          title: 'Warning',
          titleVariant: 'Warning',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Generate file',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-warning',
          modalClass: 'modal-warning',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.generateBACSFile()
          return value === true
        })
      /* eslint-enable */
    },
    generateBACScheck() {
      if (this.dataIsValid === false) return

      const result = this.checkForErrorsOrWarnings()
      if (result < 0) {
        this.displayErrorMessage()
        return
      }
      if (result > 0) {
        this.displayWarningMessage()
        return
      }

      this.generateBACSFile()
    },
    async generateBACSFile() {
      try {
        await this.$store.dispatch('app/generateBACS', { batchid: this.paymentInfo.id })
      } catch (err) {
        console.debug('Error occurred when calling API: ', err.message)
        throw err
      }
    },
  },
}
</script>

<style scoped>
.scl-total-heading {
  color: #B9B9C3;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}

.scl-total-data {
  color: #5E5873;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
</style>
