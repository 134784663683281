<template>
  <div class="scl-icon-display">
    <div
      v-if="data.item.operative[0].operativeproblems.errorcount > 0"
      class="scl-icon-margin"
    >
      <feather-icon
        :id="`error-${data.item.operative[0].ent_id}`"
        icon="AlertCircleIcon"
        size="24"
        class="text-danger text-left"
        :badge="data.item.operative[0].operativeproblems.errorcount"
        badge-classes="badge-danger"
      />
      <b-popover
        :target="`error-${data.item.operative[0].ent_id}`"
        triggers="hover"
        placement="topright"
        variant="light"
      >
        <template
          #title
        >
          <span class="scl-error-heading">Error</span>
        </template>
        <p
          v-for="(error, index) in data.item.operative[0].operativeproblems.errors"
          :key="index"
        >
          <span class="scl-error-text">{{ error.message }}</span>
        </p>
      </b-popover>
    </div>
    <div v-if="data.item.operative[0].operativeproblems.warningcount > 0">
      <feather-icon
        :id="`warning-${data.item.operative[0].ent_id}`"
        icon="AlertCircleIcon"
        size="24"
        class="text-warning text-left"
        :badge="data.item.operative[0].operativeproblems.warningcount"
        badge-classes="badge-warning"
      />
      <b-popover
        :target="`warning-${data.item.operative[0].ent_id}`"
        triggers="hover"
        placement="topright"
        variant="light"
        class="popover"
      >
        <template
          #title
          class="popover-header"
        >
          <span class="scl-warning-heading">Warning</span>
        </template>
        <p
          v-for="(warning, index) in data.item.operative[0].operativeproblems.warnings"
          :key="index"
        >
          <span class="scl-warning-text">{{ warning.message }}</span>
        </p>
      </b-popover>
    </div>
  </div>
</template>

<script>
import {
  BPopover,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'PaymentOperativeIssueIcons',
  components: {
    FeatherIcon,
    BPopover,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    popoverVariant() {
      if (this.data.item.operative[0].operativeproblems.errorcount > 0) return 'danger'

      return 'scl-issue-color'
    },
    problemCount() {
      return this.data.item.operative[0].operativeproblems.errorcount + this.data.item.operative[0].operativeproblems.warningcount
    },
    problemClass() {
      if (this.data.item.operative[0].operativeproblems.errorcount > 0) return 'text-danger text-left'

      return 'text-warning text-left'
    },
    badgeClass() {
      if (this.data.item.operative[0].operativeproblems.errorcount > 0) return 'badge-danger badge-glow'

      return 'badge-warning badge-glow'
    },
  },
}
</script>

<style scoped>
.popover {
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.scl-error-heading {
  color: #FF5358;
}
.scl-warning-heading {
  color: #ff9f43;
}
.scl-icon-display {
  display:flex;
}
.scl-icon-margin {
  margin-right: 6px;
}

</style>
