<template>
  <div class="text-left">
    <template v-if="status < 3">
      <div
        class="text-center"
      > -
      </div>
    </template>
    <template v-else>
      <b-badge
        :variant="statusColor"
        class="pl-0.5 pr-0.5"
      >
        {{ statusText }}
      </b-badge>
    </template>
  </div>
</template>
<script>

import {
  BBadge,
} from 'bootstrap-vue'

export default {

  name: 'PaymentStatusComponent',
  components: {
    BBadge,
  },
  props: {
    status: {
      type: Number,
      required: true,
    },
    // statuses: {
    //   type: Array,
    //   required: false,
    //   default: [],
    // },
  },
  computed: {
    statusColor() {
      if (this.status < 3) {
        return 'secondary'
      }
      if (this.status === 3) {
        return 'light-warning'
      }
      if (this.status === 4) {
        return 'primary'
      }
      if (this.status === 5) {
        return 'danger'
      }
      if (this.status === 6) {
        return 'light-info'
      }
      if (this.status === 7) {
        return 'light-dark'
      }
      if (this.status === 8) {
        return 'light-success'
      }
      if (this.status === 9) {
        return 'light-primary'
      }

      return ''
    },
    statusText() {
      if (this.status < 3) {
        return ' - '
      }
      if (this.status === 3) {
        return 'Hold Payment'
      }
      if (this.status === 4) {
        return 'Paid'
      }
      if (this.status === 5) {
        return 'Sage Rejected'
      }
      if (this.status === 6) {
        return 'Sage Hold'
      }
      if (this.status === 7) {
        return 'Sage Ready'
      }
      if (this.status === 8) {
        return 'Pushed To Sage'
      }
      if (this.status === 9) {
        return 'Completed'
      }

      return ''
    },
  },
}
</script>

<style scoped>

</style>
